import React, { useState } from 'react';
import { Block, c_type, Langs } from '../../../core/api/interfaces';
import i18n from '../../../core/i18n/i18n';
import { getLang } from '../../../core/utils';

const EditorNode = ({model, lang, onDragSelectNode, isStart, isSelected}: {model: Block, lang: keyof Langs, isSelected: boolean, onDragSelectNode: (state: boolean) => void, isStart: boolean}) => {
  const [isDragSelected, setDragSelected] = useState(false);

  const toggleCheckboxChange = () => {
    const newState = !isDragSelected
    setDragSelected(newState);
    onDragSelectNode(newState);
  }

  const hasExits = () => {
    return model.exits.reduce((prev, curr) => prev || curr.exit_dir !== '0', false)
  }

  let bgColor = '#ffffff';
  if (model.type === 'RT') {
    bgColor = '#99ccff';
  } else if (model.type === 'SW') {
    bgColor = '#ccff99';
  } else if (model.type === 'NT') {
    bgColor = '#ffffcc';
  }

  if (model.type === 'FN') {
    return <></>;
  }

  return (
    <div
      className={
        "shadow text-dark rounded pb-2"
        + (isSelected ? ' border-warning' : '')
        + (!hasExits() ? ' border-danger' : '')
        + (isStart ? ' border-primary' : '')
        + (model.type === 'NT' ? ' note-block' : '')
      }
      style={{borderWidth: '3px', borderStyle: 'solid', boxSizing: 'border-box', borderColor: 'transparent', backgroundColor: bgColor}}
    >
      <div className={`p-2 ${model.type === 'RT' ? 'pb-5' : ''}`}>
        <input type="checkbox" name="" id="" checked={isDragSelected} onChange={toggleCheckboxChange} />
        <span className="h4 ml-2">
          {(() => {
            if (model.type === 'SW') {
              return <>{i18n.translate('general.process-call')}</>;
            } else if (model.type === 'NT') {
              return <>{i18n.translate('general.note')}</>;
            } else if (model.description) {
              return <>{model.description}</>;
            } else {
              return <>{i18n.translate('general.noname')}</>;
            }
          })()}
        </span>
      </div>

      {model.type === 'SW' && (
        <div className="p-2 overflow-hidden mb-3">
          <p>{model.description}</p>
        </div>
      )}

      {model.type === 'NT' && (
        <>
          <div className="px-2 mb-3">
            <p>{model.description}</p>
          </div>
        </>
      )}

      {(model.type === '' || model.type === null) && model.exec && (
        <div className="p-1 bg-dark text-white overflow-hidden mb-3" style={{height: '100px'}}>
          <p>{model.exec}</p>
        </div>
      )}

      {(model.type === '' || model.type === null) && model.elements && model.elements.length > 0 && (
        <div className="px-2 mb-3">
          {model.elements.map((element, idx) => (
            <div key={'node_' + element.elem_id + idx} className="mt-2">
              {(() => {
                switch (element.c_type) {
                  case c_type.MESSAGE:
                  case c_type.TXT_ASSIGN:
                    return (
                      <p
                        className="d-block w-100 text-left px-1"
                      >
                        {getLang(element.langs, lang).label}
                      </p>
                    );
                  case c_type.INPUT:
                    return (
                      <input
                        className="d-block w-100 text-left px-1"
                        type="email"
                        disabled={true}
                        placeholder={getLang(element.langs, lang).label}
                      />
                    );
                  case c_type.BUTTON:
                    return (
                      <></>
                      // <input
                      //   className="d-block w-100 text-left px-1"
                      //   type="button"
                      //   disabled={true}
                      //   value={getLang(element.langs, lang).label}
                      // />
                    );
                  case c_type.MEDIA:
                    return (
                      <img
                        className="d-block w-100 px-1"
                        src={getLang(element.langs, lang).uri}
                        title={element.var_name}
                        alt={element.var_name}
                      />
                    );
                  case c_type.LINK:
                    return (
                      <p
                        className="d-block w-100 px-1"
                        title={element.var_name}
                      >{getLang(element.langs, lang).uri}</p>
                    );
                  case c_type.FILE:
                  case c_type.SELECT:
                    return (
                      <select className="form-control d-block w-100 px-1" disabled value={element.var_name}>
                        <option disabled>{element.var_name}</option>
                      </select>
                    );
                  default:
                    return (
                      <p className="d-block w-100 text-left px-1">c_type {element.c_type} not recognized</p>
                    );
                }
              })()}
            </div>
          ))}
        </div>
      )}

      <div className="px-2">
        {model.exits.map((exit, idx) => (
          <div key={exit.exit_dir + '-' + idx} className="bg-gray-400 mb-2 rounded p-2">
            <p className="ellipsis">
              {(() => {
                const el = model.elements.find(el => el.c_type === c_type.BUTTON && Number(el.exit_num) === idx)
                return el ? el.langs[lang].label : i18n.translate('general.exit') + ' ' + idx
              })()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditorNode;
