import React, { useState, useEffect, useRef } from 'react';
import Editor, { loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import i18n from '../../../core/i18n/i18n';

const useMyMonaco = ({params}: any) => {
  const monacoRef = useRef<any>(null); // Use a ref to store Monaco instance

  // ID univoco per l'istanza, per fare in modo che l'autocomplete sia funzionante solo su se stesso
  const editorId = useRef(`editor-${Math.random().toString(36).substring(2, 9)}`);

  // Init Monaco editor once
  useEffect(() => {
    if (!monacoRef.current) {
      loader.init().then((monacoInstance) => {
        monacoRef.current = monacoInstance; // Store Monaco instance
        monacoInstance.languages.register({ id: 'php-snippet' });

        monacoInstance.languages.setMonarchTokensProvider('php-snippet', {
          tokenizer: {
            root: [
              [/\bfunction\b/, 'keyword'],
              [/\b(return|if|else|while|for|foreach|break|continue|switch|case|default)\b/, 'keyword'],
              [/[{}()[\]]/, '@brackets'],
              [/[$][a-zA-Z_]\w*/, 'variable'],
              [/[a-zA-Z_]\w*/, 'identifier'],
              [/[;,.]/, 'delimiter'],
              [/"([^"\\]|\\.)*$/, 'string.invalid'],
              [/"([^"\\]|\\.)*"/, 'string'],
              [/'([^'\\]|\\.)*$/, 'string.invalid'],
              [/'([^'\\]|\\.)*'/, 'string'],
              [/\d+/, 'number'],
              [/\/\/.*$/, 'comment'],
              [/\/\*/, 'comment', '@comment'],
            ],
            comment: [
              [/\*\//, 'comment', '@pop'],
              [/[^*/]+/, 'comment'],
              [/./, 'comment'],
            ],
          },
        });

        monacoInstance.languages.setLanguageConfiguration('php-snippet', {
          autoClosingPairs: [
            { open: '{', close: '}' },
            { open: '[', close: ']' },
            { open: '(', close: ')' },
            { open: '"', close: '"' },
            { open: "'", close: "'" },
          ],
        });
      });
    }
  }, []);

  // Register the completion provider when params change
  useEffect(() => {
    if (monacoRef.current && monaco.languages) {
      const disposable = monacoRef.current.languages.registerCompletionItemProvider('php-snippet', {
        provideCompletionItems: (model: any, position: any) => {
          // Controlla se l'editor corrente è quello attivo
          if (model.uri.path.substring(1) !== editorId.current) {
            return { suggestions: [] };
          }

          const word = model.getWordUntilPosition(position);
          const range = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: word.startColumn,
            endColumn: word.endColumn,
          };

          const suggestions = params
            .filter((param: any) => param.trim() !== '')
            .map((param: any) => {
              const insertText = param.startsWith('$') ? param : `$${param}`;
              console.log(param, insertText);
              return {
                label: param,
                kind: monacoRef.current.languages.CompletionItemKind.Variable,
                insertText: insertText, // Only prepend $ if it's missing
                detail: 'Parametro della funzione',
                range,
              };
            });


          return { suggestions };
        },
      });

      // Clean up the disposable when params change
      return () => {
        disposable.dispose();
      };
    }
  }, [params]);

  return {
    editorId,
  }
}

const FunctionCodeEditor = ({ value = '', onChange, onDelete }: any) => {
  const [functionName, setFunctionName] = useState('');
  const [params, setParams] = useState<string[]>(['']);
  const [code, setCode] = useState('');

  const { editorId } = useMyMonaco({ params })

  const parseFunction = () => {
    if (value == '') {
      setFunctionName('')
      setParams([''])
      setCode('')
      return;
    }

    const match = value.match(/function\s+(\w+)?\(([^)]*)\)\s*{([\s\S]*)}/);
    console.log(match)
    if (!match) {
      throw new Error('Wrong function format')
    };

    setFunctionName(match[1] || '');
    setParams([...match[2].split(',').map((p: any) => p.trim().slice(1)).filter(Boolean), '']);
    setCode(match[3].trim().replace(/}$/, '') || ''); // Rimuovi eventuale chiusura extra
  };

  useEffect(() => {
    parseFunction()
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange(generateFunctionString())
    }
  }, [functionName, params, code])

  const generateFunctionString = () => {
    return `function ${functionName}(${params.filter(p => p !== '').map(p => `$${p}`).join(', ')}) {\n${code}\n}`;
  };

  const handleFunctionNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionName(e.target.value);
  };

  const handleParamsChange = (index: number, newValue: string) => {
    setParams((prevParams) => {
      const updatedParams = [...prevParams];
      updatedParams[index] = newValue;
      if (index === prevParams.length - 1 && newValue !== '') {
        updatedParams.push('');
      }
      return updatedParams;
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'Backspace' && params[index] === '') {
      removeParam(index);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const handleBlur = (index: number) => {
    setParams((prevParams) => {
      if (index < prevParams.length - 1 && prevParams[index] === '') {
        return prevParams.filter((_, idx) => idx !== index);
      }
      return prevParams;
    });
  };

  const removeParam = (index: number) => {
    if (params.length > 1 && index !== params.length - 1) {
      setParams((prevParams) => prevParams.filter((_, idx) => idx !== index));
    }
  };

  const handleEditorChange = (newValue: string | undefined) => {
    setCode(newValue || '');
  };

  const [isHidden, setIsHidden] = useState(false);

  return (
    <div className='bg-white text-dark'>
      <div className='d-flex'>
        <div>
          <div className='d-inline-block p-1' onClick={() => setIsHidden(!isHidden)}>{isHidden ? '►' : '▼'}</div>
          <span>function </span>
          <input
            type="text"
            value={functionName}
            onChange={handleFunctionNameChange}
            placeholder=""
            style={{
              minWidth: '30px',
              width: functionName.length + 1 + 'ch',
              border: functionName.length === 0 ? '1px solid red' : ''
            }}
          />
          <span>{'('}</span>
          {params.map((param, index) => (
            <React.Fragment key={index}>
              $
              <input
                type="text"
                value={param}
                onChange={(e) => handleParamsChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onBlur={() => handleBlur(index)}
                placeholder={`param${index + 1}`}
                style={{ width: param.length + 1 + 'ch' }}
              />
              {index < params.length - 1 && <span>, </span>}
            </React.Fragment>
          ))}
          <span> {') {'} </span>
        </div>
        <div onClick={() => {
          if (window.confirm(i18n.translate('general.sure-delete'))) {
            onDelete()
          }
        }} className='ml-auto p-1'>x</div>
      </div>

      <div style={{
        visibility: isHidden ? 'hidden' : 'visible',
        height: isHidden ? '0px' : 'unset',
      }}>
        <Editor
          path={editorId.current}
          height="200px"
          defaultLanguage="php-snippet"
          value={code}
          onChange={handleEditorChange}
          options={{
            minimap: { enabled: false },
            readOnly: false,
            fontSize: 14,
            scrollBeyondLastLine: false,
          }}
        />
      </div>

      <div className='ml-4'>
        <span>{' }'}</span>
      </div>
    </div>
  );
};

export default FunctionCodeEditor;
