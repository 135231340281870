import { ConnectorSpec, DragEventCallbackOptions, EndpointOptions, EndpointSpec, jsPlumb, jsPlumbInstance, OnConnectionBindInfo } from 'jsplumb';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Block, Workflow } from '../../../core/api/interfaces';
import EditorNode from '../molecules/EditorNode';

import './WFEditor.scss';
import WFEditorContainer from '../molecules/WFEditorContainer';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../core/i18n/i18n';
import WorkflowActions from '../../../core/stores/workflows/actions';
import Api from '../../../core/api/api';

// dimensioni in px delle exits
const exitMarginBottom = 16;
const exitHeight = 34.67;
const exitMidHeight = exitHeight / 2;
const exitSingleOffset = exitHeight + 8;
const getExitOffset = (pos: number) => -(exitMarginBottom + exitMidHeight + (pos * exitSingleOffset));

const getIndexFromOffset = (node: Block, offset: number) => {
  let pos = -(exitMarginBottom + exitMidHeight + offset) / exitSingleOffset
  pos = Math.abs(Math.round(pos));

  pos = node.exits.length - pos - 1;
  return pos;
}

const sourceEndpointStyle = {
  fill: "#ff6600",
  fillStyle: "#ff6600"
};
const targetEndpointStyle = {
  fill: "#ff6600",
  fillStyle: "#ff6600"
};
const endpoint: EndpointSpec = ["Dot", {
  cssClass: "editor-canvas-endpoint",
  radius: 8,
  hoverClass: "editor-canvas-endpoint--hover"
}];
const connector: ConnectorSpec = ["Flowchart", {
  cornerRadius: '5',
  cssClass: "editor-canvas-node-connection",
  hoverClass: "editor-canvas-node-connection--hover"
}];
const connectorStyle = {
  strokeWidth: 6,
  stroke: "#33cc33",
  strokeStyle: "#33cc33"
};
const connectorHoverStyle = {
  strokeWidth: 6,
  stroke: "#ff0000",
  strokeStyle: "#ff0000"
};
const anSourceEndpoint: EndpointOptions = {
  endpoint: endpoint,
  paintStyle: sourceEndpointStyle,
  hoverPaintStyle: {
    fill: "#449999",
    // fillStyle: "#449999"
  },
  isSource: true,
  maxConnections: 1,
  // Anchor: ["TopCenter"],
  connector: connector,
  connectorStyle: connectorStyle,
  connectorHoverStyle: connectorHoverStyle,
};
const anTargetEndpoint: EndpointOptions = {
  endpoint: ["Rectangle", {
    width: 16,
    height: 16,
    cssClass: 'editor-canvas-endpoint editor-canvas-endpoint-target',
    // hoverClass: string
  }],
  paintStyle: targetEndpointStyle,
  hoverPaintStyle: {
    fill: "#449999",
    // fillStyle: "#449999"
  },
  isTarget: true,
  maxConnections: -1,
  // Anchor: ["BottomCenter"],
  connector: connector,
  connectorStyle: connectorStyle,
  connectorHoverStyle: connectorHoverStyle,
};

interface OnBlockDraggedValues {
  block_id: string;
  x_pos: number;
  y_pos: number;
}

function useJsPlumb({
  nodes, startBlockId, onBlockDragged, isEditable, onAddConnection, onDelConnection, dragSelection, lang, scale
}: {
  nodes: Block[],
  startBlockId: string,
  onBlockDragged: (values: OnBlockDraggedValues) => void,
  isEditable: boolean,
  onAddConnection: (values: any) => void,
  onDelConnection: (values: any) => void,
  dragSelection: string[],
  lang: string,
  scale: number,
}) {
  const isStartBlock = (block_id: string) => block_id === startBlockId;
  const isFnBlock = (block_id: string) => nodes.find(b => b.block_id == block_id)?.type == 'FN'

  const [jsPlumbInstance] = useState<jsPlumbInstance>(
    jsPlumb.getInstance({
      Container: "canvas",
    })
  );

  const drawLines = () => {
    if (!jsPlumbInstance) return;

    edges.forEach((edge, idx) => {
      const source = edge.sourceId + "-source-" + edge.sourceIdx;
      let target = edge.targetId + "-target-left"

      jsPlumbInstance.connect({
        uuids: [
          source,
          target
        ],
        cssClass: 'editor-canvas-node-connection'
      });
    });
  }

  const edges = useMemo(() => {
    return nodes.flatMap(node => {
      return node.exits
        .map((el, idx) => ({
          ...el,
          sourceIdx: idx,
        }))
        .filter(el => String(el.exit_dir) !== '0')
        .map((el) => {
          return {
            sourceIdxCount: node.exits.length,
            sourceIdx: el.sourceIdx,
            sourceId: node.block_id,
            targetId: el.exit_dir
          }
        })
    });
  }, [nodes]);

  useEffect(() => {
    (jsPlumbInstance as any).clearDragSelection();
    (jsPlumbInstance as any).addToDragSelection(dragSelection);
  }, [dragSelection])

  useEffect(() => {
    // add endpoints
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const nUUID = node.block_id;

      for (let index = 0; index < node.exits.length; ++index) {
        if (node.type == 'FN') {
          // non disegnare ancore per i blocchi funzione
          continue;
        }
        const offset = getExitOffset(node.exits.length - index - 1)

        jsPlumbInstance.addEndpoint(nUUID, anSourceEndpoint, {
          uuid: nUUID + "-source-" + index,
          enabled: isEditable,
          anchor: [
            // [x, y, dx, dy]
            [1, 1, 1, 0, 0, offset], // BottomRight, offsetTop
            [0, 1, -1, 0, 0, offset], // BottomLeft, offsetTop
          ],
          maxConnections: -1
        });
      }

      if (!isStartBlock(nUUID) && !isFnBlock(nUUID)) {
        jsPlumbInstance.addEndpoints(
          nUUID,
          [
            {
              ...anTargetEndpoint,
              enabled: isEditable,
              uuid: nUUID + "-target-left",
              anchor: [
                // [x, y, dx, dy]
                [0, 0, -1, 0, 0, 23], // TopLeft, offsetTop
              ],
              maxConnections: -1
            },
          ]
        );
      }
    }

    // draw lines
    drawLines();

    if (isEditable) {
      // set nodes draggable
      for (let i = 0; i < nodes.length; i++) {
        const nUUID = nodes[i].block_id;
        jsPlumbInstance.draggable(nUUID, {
          start: (params: DragEventCallbackOptions) => {
            // console.log('start', params)
          },
          drag: (params: DragEventCallbackOptions) => {
            const ui = params.el;
            ui.style.left = params.pos[0] + 'px';
            ui.style.top = params.pos[1] + 'px';
            jsPlumbInstance.revalidate(nUUID);
          },
          stop: (params: DragEventCallbackOptions) => {
            const selection = (params as any).selection
            selection.forEach(([el, {left, top}]: any) => {
              console.log(el, left, top);
              onBlockDragged({
                block_id: el.id,
                x_pos: left,
                y_pos: top,
              });
            })
          },
        });
      }
    }

    jsPlumbInstance.bind("connection", (connObj: OnConnectionBindInfo, originalEvent: Event) => {
      if (originalEvent === undefined){
        return;
      }
      const node = nodes.find(node => node.block_id === connObj.sourceId) as Block;
      const offset = (connObj.sourceEndpoint.anchor as any).anchors[0].offsets[1];

      const pos = getIndexFromOffset(node, offset);

      onAddConnection({
        index: pos,
        source: connObj.sourceId,
        target: connObj.targetId,
      });
    });
    // jsPlumbInstance.bind("contextmenu", onDelConnection);
    jsPlumbInstance.bind("connectionDetached", (connObj: OnConnectionBindInfo, originalEvent: Event) => {
      if (originalEvent === undefined){
        return;
      }
      const node = nodes.find(node => node.block_id === connObj.sourceId) as Block;
      const offset = (connObj.sourceEndpoint.anchor as any).anchors[0].offsets[1];

      const pos = getIndexFromOffset(node, offset);

      onDelConnection({
        index: pos,
        source: connObj.sourceId,
        target: connObj.targetId,
      });
    });

    return () => {
      if (!jsPlumbInstance) return;
      jsPlumbInstance.reset();
      jsPlumbInstance.deleteEveryConnection()
      jsPlumbInstance.deleteEveryEndpoint()
    }
  }, [edges]);

  return jsPlumbInstance;
}

function useDragSelection() {
  const [dragSelection, _setDragSelection] = useState<string[]>([]);
  const addToDragSelection = (el: string) => {
    _setDragSelection([...dragSelection, el]);
  }
  const removeFromDragSelection = (el: string) => {
    _setDragSelection(dragSelection.filter(sel => sel !== el));
  }

  return {
    dragSelection, addToDragSelection, removeFromDragSelection
  }
}

const TmpFakeHeader = ({
  onIncreaseZoom,
  onDecreaseZoom,
  resetPosition,
  scale,
  containerRef,
  left,
  top,
}: any) => {
  const dispatch = useDispatch();
  const { selectedBlockId, selectedWorkflowId, data } = useSelector((state: any) => state.workflows);
  const workflow = useMemo(() => data[selectedWorkflowId] as Workflow, [data, selectedWorkflowId]);
  const startBlockId = workflow.start_block_id;
  const blocks = workflow.blocks;

  const [showContextCreate, setShowContextCreate] = useState(false);

  const handleClick = (e: any) => {
    if(!(e.target instanceof HTMLButtonElement)) {
      setShowContextCreate(false);
    }
  };

  const handleKeyboard = (e: any) => {
    if (e.target.tagName === 'BODY' && e.keyCode === 46) { // Delete
      handleDeleteBlock();
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyboard);

    return () => {
      document.removeEventListener('keyup', handleKeyboard);
    }
  }, [selectedBlockId, startBlockId])

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    }
  }, []);

  const handleDeleteBlock = useCallback(() => {
    console.log(selectedBlockId, startBlockId)
    if (selectedBlockId && selectedBlockId !== startBlockId) {
      window.confirm(i18n.translate('general.sure-delete')) && dispatch(WorkflowActions.deleteLocalWorkflowBlock())
    }
  }, [selectedBlockId, startBlockId]);

  return (
    <div className="position-relative bg-primary h-0">
      <div style={{left: '16px', top: '-54px', right: 0, position: 'absolute', userSelect: 'none'}}>
        <span className="ml-3">
          <span
            className="cursor-pointer bg-secondary text-white rounded-circle text-center" style={{width: '24px', height: '24px', display: "inline-block", lineHeight: '20px'}}
            onClick={() => {
              onDecreaseZoom()
            }}
          >
            -
          </span>
          <span
            className="cursor-pointer mx-2 text-white"
            onClick={resetPosition}
          >
            {(scale*100).toFixed(0)}%
          </span>
          <span
            className="cursor-pointer bg-secondary text-white rounded-circle text-center" style={{width: '24px', height: '24px', display: "inline-block", lineHeight: '20px'}}
            onClick={() => {
              onIncreaseZoom()
            }}
          >
            +
          </span>
        </span>

        <div className="d-inline position-relative">
          <button
            className="btn btn-light ml-3"
            title={i18n.translate('editor.add-block')}
            onClick={() => {
              setShowContextCreate(true)
            }}
          >
            <span className="pointer-events-none">
              <i className="far fa-calendar-plus"></i>
            </span>
          </button>

          {showContextCreate && (
            <div className="position-absolute bg-white border border-dark" style={{zIndex: 999, right: 0, top: '30px', borderWidth: '2px !important'}}>
              <a className="btn btn-light text-left text-nowrap w-100" title={i18n.translate('editor.add-block-st')} onClick={async () => {
                if (!containerRef || !containerRef.current) return;
                const { clientWidth, clientHeight } = containerRef.current;
                const block_id = await Api.getUUID();
                dispatch(WorkflowActions.createBlock(-left, -top, block_id))
                dispatch(WorkflowActions.selectBlock(block_id))
              }}>
                {i18n.translate('editor.add-block-st')}
              </a>

              <a className="btn btn-light text-left text-nowrap w-100" title={i18n.translate('editor.add-block-sw')} onClick={async () => {
                if (!containerRef || !containerRef.current) return;
                const { clientWidth, clientHeight } = containerRef.current;
                const block_id = await Api.getUUID();
                dispatch(WorkflowActions.createBlock(-left, -top, block_id, 'SW'))
                dispatch(WorkflowActions.selectBlock(block_id))
              }}>
                {i18n.translate('editor.add-block-sw')}
              </a>

              <a className="btn btn-light text-left text-nowrap w-100" title={i18n.translate('editor.add-block-rt')} onClick={async () => {
                if (!containerRef || !containerRef.current) return;
                const { clientWidth, clientHeight } = containerRef.current;
                const block_id = await Api.getUUID();
                dispatch(WorkflowActions.createBlock(-left, -top, block_id, 'RT'))
                dispatch(WorkflowActions.selectBlock(block_id))
              }}>
                {i18n.translate('editor.add-block-rt')}
              </a>

              <a className="btn btn-light text-left text-nowrap w-100" title={i18n.translate('editor.add-block-rt')} onClick={async () => {
                if (!containerRef || !containerRef.current) return;
                const { clientWidth, clientHeight } = containerRef.current;
                const block_id = await Api.getUUID();
                dispatch(WorkflowActions.createBlock(-left, -top, block_id, 'NT'))
                dispatch(WorkflowActions.selectBlock(block_id))
              }}>
                {i18n.translate('editor.add-block-nt')}
              </a>
            </div>
          )}
        </div>

        {selectedBlockId && selectedBlockId !== startBlockId && (
          <button className="btn btn-light text-left ml-3" title={i18n.translate('editor.delete-block')} onClick={handleDeleteBlock}>
            <i className="far fa-calendar-times"></i>
          </button>
        )}

        {selectedBlockId && selectedBlockId !== startBlockId && (
          <button className="btn btn-light ml-3" title={i18n.translate('editor.duplicates-block')} onClick={async () => {
            const block = blocks.find((b) => b.block_id === selectedBlockId);
            if (!block) return;
            const uuids = await Api.getUUID(block.elements.length + 1);
            let block_id, elem_ids = [];
            if (Array.isArray(uuids)) {
              [block_id, ...elem_ids] = uuids;
            } else {
              block_id = uuids;
            }
            dispatch(WorkflowActions.duplicatesBlock({
              block,
              block_id,
              elem_ids,
            }));
            dispatch(WorkflowActions.selectBlock(block_id))
          }}>
            <i className="far fa-calendar-check"></i>
          </button>
        )}

        <div className="d-inline-block h3 text-white truncate ml-4" style={{verticalAlign: 'top', width: 'calc(100% - 330px)'}}>
          {workflow.name}
        </div>
      </div>
    </div>
  );
}

interface UseZoomableContainerOpts {
  isZoomableWithWheel: boolean;
}

function useZoomableContainer({
  isZoomableWithWheel,
}: UseZoomableContainerOpts) {
  const [scale, setScale] = useState(1);
  const rollerRatio = .05;

  const onWheel = (e: React.WheelEvent) => {
    console.log(e.target)
    if (!(e.target as any).querySelector('.editor-canvas')) {
      return;
    }
    const delta = Math.sign(e.deltaY) * scale * rollerRatio;
    setScale(scale - delta);
  };

  useEffect(() => {
    if (isZoomableWithWheel) {
      document.addEventListener('wheel', onWheel as any);
    } else {
      document.removeEventListener('wheel', onWheel as any);
    }

    return () => {
      document.removeEventListener('wheel', onWheel as any);
    };
  }, [isZoomableWithWheel, scale]);

  return {
    scale,
    setScale,
  }
}

interface UseDraggableContainerOpts {
  initLeft: number;
  initTop: number;
  scale: number;
}

function useDraggableContainer(opts: UseDraggableContainerOpts) {
  const [isDragging, setDragging] = useState(false);
  const [initLeft, setInitLeft] = useState(opts.initLeft);
  const [initTop, setInitTop] = useState(opts.initTop);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);

  const moveTo = (left: number, right: number) => {
    setDragging(false);
    setLeft(left);
    setTop(right);
  };

  useEffect(() => {
    const onMouseMove = (e: React.MouseEvent) => {
      if (!isDragging) {
        return;
      }
      setLeft(left + (e.pageX - initLeft) * (1 / opts.scale))
      setTop(top + (e.pageY - initTop) * (1 / opts.scale))
    };

    const onMouseDown = (e: React.MouseEvent) => {
      if (!(e.target as any).querySelector('.editor-canvas')) {
        return;
      }
      setInitLeft(e.pageX);
      setInitTop(e.pageY);
      setDragging(true);
    };

    const onMouseUpOrLeave = (e: React.MouseEvent) => {
      setDragging(false);
    };

    document.addEventListener('mousemove', onMouseMove as any);
    document.addEventListener('mousedown', onMouseDown as any);
    document.addEventListener('mouseup', onMouseUpOrLeave as any);
    document.addEventListener('mouseleave', onMouseUpOrLeave as any);
    return () => {
      document.removeEventListener('mousemove', onMouseMove as any);
      document.removeEventListener('mousedown', onMouseDown as any);
      document.removeEventListener('mouseup', onMouseUpOrLeave as any);
      document.removeEventListener('mouseleave', onMouseUpOrLeave as any);
    };
  }, [isDragging]);

  return {
    left,
    top,
    moveTo,
  };
}

const WFEditor = ({
  workflow,
  onBlockSelect,
  isEditable,
  onBlockDragged,
  onAddConnection,
  onDelConnection,
}: {
  workflow: Workflow,
  onBlockSelect: (block_id: string) => void,
  isEditable: boolean,
  onBlockDragged: (values: OnBlockDraggedValues) => void,
  onAddConnection: (values: any) => void,
  onDelConnection: (values: any) => void,
}) => {
  const nodes = workflow.blocks
  const { selectedBlockId }= useSelector((state: any) => state.workflows);

  const { lang } = workflow;
  const isStartBlock = (block_id: string) => block_id === workflow.start_block_id;

  const firstBlock = nodes.find(node => node.block_id === workflow.start_block_id) as Block;

  const resetPosition = () => {
    setScale(1);
    jsPlumbInstance.setZoom(1);
    const w = (containerRef.current as any).offsetWidth / 2;
    const h = (containerRef.current as any).offsetHeight / 2;
    moveTo(-Number(firstBlock.x_pos) - w, -Number(firstBlock.y_pos) - h);
  };

  const { dragSelection, addToDragSelection, removeFromDragSelection } = useDragSelection()

  const { scale, setScale } = useZoomableContainer({
    isZoomableWithWheel: true,
  });

  const jsPlumbInstance = useJsPlumb({
    nodes,
    startBlockId: workflow.start_block_id,
    onBlockDragged,
    isEditable,
    onAddConnection,
    onDelConnection,
    dragSelection,
    lang,
    scale
  });

  const { left, top, moveTo } = useDraggableContainer({
    initLeft: 0,
    initTop: 0,
    scale,
  });

  const zoomManualRateo = 0.05;
  const containerRef = useRef(null);

  useEffect(() => {
    resetPosition();
  }, []);

  return (
    <>
      <TmpFakeHeader
        onDecreaseZoom={() => {
          jsPlumbInstance.setZoom(scale - zoomManualRateo, true)
          setScale(scale - zoomManualRateo);
        }}
        onIncreaseZoom={() => {
          jsPlumbInstance.setZoom(scale + zoomManualRateo, true)
          setScale(scale + zoomManualRateo);
        }}
        resetPosition={resetPosition}
        scale={scale}
        containerRef={containerRef}
        left={left}
        top={top}
      />

      <WFEditorContainer
        onScaleChange={(scale: any) => {
          jsPlumbInstance.setZoom(scale, true)
        }}
        scale={scale}
        left={left}
        top={top}
        containerRef={containerRef}
      >
        {nodes.map((node) => {
          return (
            <div
              key={'editor-canvas-node_' + node.block_id}
              id={node.block_id}
              style={{
                left: node.x_pos + 'px',
                top: node.y_pos + 'px',
              }}
              className="editor-canvas-node"
              onClick={() => {
                console.log('cliccato nodo', node);
                onBlockSelect(node.block_id)
              }}
            >
              <EditorNode
                model={node}
                lang={lang}
                isStart={isStartBlock(node.block_id)}
                isSelected={selectedBlockId === node.block_id}
                onDragSelectNode={(isDragSelected) => {
                  if (isDragSelected) {
                    addToDragSelection(node.block_id);
                  } else {
                    removeFromDragSelection(node.block_id);
                  }
                }}
              />
            </div>
          )
        })}
      </WFEditorContainer>
    </>
  );
};

export default WFEditor;
